import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d252a94c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "search-warp" }
const _hoisted_3 = { class: "slot" }
const _hoisted_4 = { class: "search-box" }
const _hoisted_5 = ["placeholder", "onKeyup"]

import { ref, computed, reactive } from "vue";
import { useRoute } from "vue-router";
import { useCity } from "@/pinia/api/useCity.js";
import { storeToRefs } from "pinia";
import { useNewsStore } from "@/pinia/api/useDetailOffers.js";

export default {
  props: {
  placeholder: String,
},
  emits: "search",
  setup(__props, { emit }) {

const props = __props

const store = useNewsStore(); // 公司详情页的贸易商机
const cityStore = useCity();
cityStore.getCityData(); // 初始化city数据
const { city } = storeToRefs(cityStore);
// 向父元素发送事件

// props


const route = useRoute();
//解决动态 路由 父亲样式的问题
const parentStyle = computed(() => {
  return route.path;
});
/* const state = ref({
  price: 0,
}); */

// 保存关于下拉框 城市的数据
const cityDate = reactive({
  currentCityCode: "",
  children: [],
  currentChildrenCityCode: "",
});

//  点击城市的时候更换成改城市相对应的下级城市
cityDate.children = computed(() => {
  if (cityDate.currentCityCode === undefined) {
    return [
      {
        title: "所在市",
        id: "-000011110",
      },
    ];
  } else {
    const children = city.value.filter(
      (item) => item.id === cityDate.currentCityCode
    );
    return children.length && children[0].children;
  }
});

// 市改变的时候触发
const changeCity = (id) => {
  store.params.regionId = id;
  store.checkNewsCloumn({ page: 1 });
};

// 点击搜索的时候 触发的事件
const keyWords = ref("");
const search = () => {
  emit("search", keyWords);
};

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "date")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "search",
          placeholder: __props.placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((keyWords).value = $event)),
          onKeyup: _withKeys(search, ["enter"])
        }, null, 40, _hoisted_5), [
          [_vModelText, keyWords.value]
        ]),
        _createElementVNode("button", {
          class: "search-btn",
          onClick: search
        }, "搜索")
      ])
    ])
  ]))
}
}

}